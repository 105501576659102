<template>
    <div>
        <v-card>
            <v-card-actions class="mx-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3 mb-3 white--text" small color="green" depressed @click="export2xls">Export to XLS</v-btn>
            </v-card-actions>
            <v-card-subtitle>
                <div style="text-align:center; font-weight:bold; font-size:16px; padding:10px">FORM 005 - Sisa Stok, Stok IN & Pemakaian Mingguan</div>

                <v-row class="compact-form my-2">
                    <v-col cols="1">
                        Tanggal
                    </v-col>
                    <v-col cols="3" class="">
                        <v-menu v-model="dateMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="reportDate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="reportDate" :allowed-dates="allowedDates"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-btn depressed color="primary" @click="runReport">Run</v-btn>
                    </v-col>
                </v-row>                

            </v-card-subtitle>

            <v-card-text class="ml-3 mr-3">

                <div id="printresep" style="overflow:auto">
                    <!-- <table style="width:98%; margin: 0px;" class="pdf-item" ref="tableBOM"> -->
                    <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th rowspan="2"><b>Kode</b></th>
                                    <th rowspan="2"><b>Nama Bahan</b></th>
                                    <th rowspan="2"><b>Stok Aktual Awal (kg)</b></th>
                                    <th rowspan="2"><b>Estimasi Sisa Stok (kg)</b></th>
                                    <th rowspan="2"><b>Stok Aktual Akhir (kg)</b></th>
                                    <th rowspan="2"><b>Retur (kg)</b></th>
                                    <th rowspan="2"><b>Selisih (kg)</b></th>
                                    <th rowspan="2"><b>% Deviasi</b></th>
                                    <th colspan="6" style="text-align: center"><b>Stock In (Kg)</b></th>
                                    <th rowspan="2"><b>Total Stok In (Kg)</b></th>
                                    <th colspan="6" style="text-align: center"><b>Pemakaian Aktual (Kg)</b></th>
                                    <th rowspan="2"><b>Total Pemakaian (Kg)</b></th>
                                    <th rowspan="2"><b>Total Busuk Mingguan (Kg)</b></th>
                                    <th rowspan="2"><b>% Busuk</b></th>
                                    <th colspan="6" style="text-align: center"><b>Busuk (Kg)</b></th>
                                    <th rowspan="2"><b>% Juice</b></th>
                                    <th colspan="6" style="text-align: center"><b>Jus (L)</b></th>
                                    <th rowspan="2"><b>Total Juice (L)</b></th>
                                </tr>
                                <tr>
                                    <th><b>Fri,&nbsp;<br> {{addDate(0) | moment}}</b></th>
                                    <th><b>Sat,&nbsp;<br> {{addDate(1) | moment}}</b></th>
                                    <th><b>Mon,&nbsp;<br> {{addDate(3) | moment}}</b></th>
                                    <th><b>Tue,&nbsp;<br> {{addDate(4) | moment}}</b></th>
                                    <th><b>Wed,&nbsp;<br> {{addDate(5) | moment}}</b></th>
                                    <th><b>Thu,&nbsp;<br> {{addDate(6) | moment}}</b></th>
                                    <th><b>Fri,&nbsp;<br> {{addDate(0) | moment}}</b></th>
                                    <th><b>Sat,&nbsp;<br> {{addDate(1) | moment}}</b></th>
                                    <th><b>Mon,&nbsp;<br> {{addDate(3) | moment}}</b></th>
                                    <th><b>Tue,&nbsp;<br> {{addDate(4) | moment}}</b></th>
                                    <th><b>Wed,&nbsp;<br> {{addDate(5) | moment}}</b></th>
                                    <th><b>Thu,&nbsp;<br> {{addDate(6) | moment}}</b></th>
                                    <th><b>Fri,&nbsp;<br> {{addDate(0) | moment}}</b></th>
                                    <th><b>Sat,&nbsp;<br> {{addDate(1) | moment}}</b></th>
                                    <th><b>Mon,&nbsp;<br> {{addDate(3) | moment}}</b></th>
                                    <th><b>Tue,&nbsp;<br> {{addDate(4) | moment}}</b></th>
                                    <th><b>Wed,&nbsp;<br> {{addDate(5) | moment}}</b></th>
                                    <th><b>Thu,&nbsp;<br> {{addDate(6) | moment}}</b></th>
                                    <th><b>Fri,&nbsp;<br> {{addDate(0) | moment}}</b></th>
                                    <th><b>Sat,&nbsp;<br> {{addDate(1) | moment}}</b></th>
                                    <th><b>Mon,&nbsp;<br> {{addDate(3) | moment}}</b></th>
                                    <th><b>Tue,&nbsp;<br> {{addDate(4) | moment}}</b></th>
                                    <th><b>Wed,&nbsp;<br> {{addDate(5) | moment}}</b></th>
                                    <th><b>Thu,&nbsp;<br> {{addDate(6) | moment}}</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj, idx) in printObj" :key="idx">
                                    <td>{{obj.code}}</td>
                                    <td>{{obj.description}}</td>
                                    <!-- Stok Aktual Awal -->
                                    <td>{{obj.stok_aktual_awal | rounded20}}</td> 
                                    <!-- Estimasi Sisa Stok -->
                                    <td>{{Number(obj.stok_aktual_awal) + Number(obj.stockin_total) - Number(obj.use_total) | rounded20}}</td>
                                    <!-- Stok Aktual Akhir -->
                                    <td>{{obj.stok_aktual_akhir | rounded20}}</td>
                                    <!-- Retur -->
                                    <td>{{obj.defect_qty | rounded20}}</td>
                                    <!-- Selisih -->
                                    <td>{{Number(obj.stok_aktual_akhir) - (Number(obj.stok_aktual_awal) + Number(obj.stockin_total) - Number(obj.use_total)) | rounded20}}</td>
                                    <!-- Deviasi -->
                                    <td>{{(Number(obj.use_total)==0)?0:((Number(obj.stok_aktual_akhir) - (Number(obj.stok_aktual_awal) + Number(obj.stockin_total) - Number(obj.use_total)))/Number(obj.use_total))*100 | rounded20}}%</td>
                                    <td>{{obj.stockin_fri | rounded20}}</td>
                                    <td>{{obj.stockin_sat | rounded20}}</td>
                                    <td>{{obj.stockin_mon | rounded20}}</td>
                                    <td>{{obj.stockin_tue | rounded20}}</td>
                                    <td>{{obj.stockin_wed | rounded20}}</td>
                                    <td>{{obj.stockin_thu | rounded20}}</td>
                                    <!-- Stock In -->
                                    <td>{{obj.stockin_total | rounded20}}</td>
                                    <td>{{obj.use_fri | rounded20}}</td>
                                    <td>{{obj.use_sat | rounded20}}</td>
                                    <td>{{obj.use_mon | rounded20}}</td>
                                    <td>{{obj.use_tue | rounded20}}</td>
                                    <td>{{obj.use_wed | rounded20}}</td>
                                    <td>{{obj.use_thu | rounded20}}</td>
                                    <!-- Total Pemakaian -->
                                    <td>{{obj.use_total | rounded20}}</td>
                                    <!-- Total Busuk -->
                                    <td>{{obj.rotten_total | rounded20}}</td>
                                    <!-- % Busuk -->
                                    <td>{{(Number(obj.rotten_total)/Number(obj.use_total))*100 | rounded20}}%</td>
                                    <td>{{obj.rotten_fri | rounded20}}</td>
                                    <td>{{obj.rotten_sat | rounded20}}</td>
                                    <td>{{obj.rotten_mon | rounded20}}</td>
                                    <td>{{obj.rotten_tue | rounded20}}</td>
                                    <td>{{obj.rotten_wed | rounded20}}</td>
                                    <td>{{obj.rotten_thu | rounded20}}</td>
                                    <!-- % Juice -->
                                    <td>{{(Number(obj.juice_total/1000)/Number(obj.use_total))*100 | rounded}}%</td>
                                    <td>{{obj.juice_fri/1000 | rounded20}}</td>
                                    <td>{{obj.juice_sat/1000 | rounded20}}</td>
                                    <td>{{obj.juice_mon/1000 | rounded20}}</td>
                                    <td>{{obj.juice_tue/1000 | rounded20}}</td>
                                    <td>{{obj.juice_wed/1000 | rounded20}}</td>
                                    <td>{{obj.juice_thu/1000 | rounded20}}</td>
                                    <!-- Total Juice -->
                                    <td>{{obj.juice_total/1000 | rounded20}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <!-- </table> -->
                </div>
            </v-card-text>
        </v-card>
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

export default {

    data: () => ({
      dialog: false,
      onLoad: false,
      printObj: [],
      dateMenu: false,
      reportDate: null,
      allowedDates: val => new Date(val+' 00:00:00').getDay() === 5
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        },
    },

    async created() {
    },

    methods: {
        addDate(nday){
            const dt = new Date(this.reportDate + ' 00:00:00')
            dt.setDate(dt.getDate()+ nday)
            return dt
        },
        async runReport() {
            this.onLoad = true
            this.printObj = (await this.$store.dispatch('getObjs',{apidomain:'others/frm005/', parameter:this.reportDate})).data
            console.log(this.printObj)
            this.onLoad = false
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        export2xls() {
            console.log(this.$refs.tableBOM.$el.firstChild.firstChild)
            const wb = utils.table_to_book(this.$refs.tableBOM.$el.firstChild.firstChild)
            writeFileXLSX(wb, "FRM-005.xlsx")

        },
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#printresep th, td {
  padding: 10px;
  font-size: 12px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
</style>